exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-agile-for-life-management-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/agile-for-life-management.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-agile-for-life-management-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-being-a-fun-dog-mom-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/being-a-fun-dog-mom.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-being-a-fun-dog-mom-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-design-entropy-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/design-entropy.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-design-entropy-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-ephemeral-bytes-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/ephemeral-bytes.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-ephemeral-bytes-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-explaining-ux-to-my-grandma-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/explaining-ux-to-my-grandma.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-explaining-ux-to-my-grandma-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-forest-garden-mansion-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/forest-garden-mansion.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-forest-garden-mansion-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-from-linear-to-circular-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/from-linear-to-circular.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-from-linear-to-circular-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-is-creative-problem-solving-creative-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/is-creative-problem-solving-creative.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-is-creative-problem-solving-creative-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-living-on-best-guesses-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/living-on-best-guesses.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-living-on-best-guesses-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-md-cheatsheet-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/md_cheatsheet.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-md-cheatsheet-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-spirit-animal-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/my-spirit-animal.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-spirit-animal-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-pack-the-bento-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/pack-the-bento.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-pack-the-bento-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-stay-focused-stay-conscious-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/stay-focused-stay-conscious.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-stay-focused-stay-conscious-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-the-fine-art-of-small-talk-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/the-fine-art-of-small-talk.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-the-fine-art-of-small-talk-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-askdeb-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/askdeb.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-askdeb-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-botanco-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/botanco.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-botanco-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-cememory-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/cememory.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-cememory-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-classmate-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/classmate.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-classmate-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-closet-clothes-clutter-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/closet-clothes-clutter.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-closet-clothes-clutter-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-cut-weight-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/cut-weight.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-cut-weight-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-frame-review-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/frame-review.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-frame-review-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-gccc-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/gccc.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-gccc-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-red-packet-design-2021-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/red-packet-design-2021.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-red-packet-design-2021-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-shopline-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/shopline.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-shopline-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-spring-hills-2018-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/spring-hills-2018.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-spring-hills-2018-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-tyson-accessibility-guideline-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/tyson-accessibility-guideline.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-tyson-accessibility-guideline-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-tyson-ars-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/tyson-ars.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-tyson-ars-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-tyson-design-system-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/tyson-design-system.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-tyson-design-system-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-tyson-translate-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/tyson-translate.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-tyson-translate-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-wing-pal-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/wing-pal.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-work-wing-pal-mdx" */),
  "component---src-pages-font-js": () => import("./../../../src/pages/font.js" /* webpackChunkName: "component---src-pages-font-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-agile-for-life-management-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/agile-for-life-management.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-agile-for-life-management-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-being-a-fun-dog-mom-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/being-a-fun-dog-mom.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-being-a-fun-dog-mom-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-design-entropy-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/design-entropy.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-design-entropy-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-ephemeral-bytes-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/ephemeral-bytes.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-ephemeral-bytes-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-explaining-ux-to-my-grandma-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/explaining-ux-to-my-grandma.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-explaining-ux-to-my-grandma-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-forest-garden-mansion-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/forest-garden-mansion.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-forest-garden-mansion-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-from-linear-to-circular-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/from-linear-to-circular.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-from-linear-to-circular-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-is-creative-problem-solving-creative-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/is-creative-problem-solving-creative.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-is-creative-problem-solving-creative-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-living-on-best-guesses-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/living-on-best-guesses.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-living-on-best-guesses-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-md-cheatsheet-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/md_cheatsheet.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-md-cheatsheet-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-my-spirit-animal-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/my-spirit-animal.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-my-spirit-animal-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-pack-the-bento-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/pack-the-bento.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-pack-the-bento-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-stay-focused-stay-conscious-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/stay-focused-stay-conscious.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-stay-focused-stay-conscious-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-the-fine-art-of-small-talk-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/blog/the-fine-art-of-small-talk.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-blog-the-fine-art-of-small-talk-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-askdeb-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/askdeb.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-askdeb-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-botanco-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/botanco.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-botanco-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-cememory-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/cememory.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-cememory-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-classmate-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/classmate.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-classmate-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-closet-clothes-clutter-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/closet-clothes-clutter.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-closet-clothes-clutter-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-cut-weight-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/cut-weight.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-cut-weight-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-frame-review-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/frame-review.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-frame-review-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-gccc-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/gccc.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-gccc-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-red-packet-design-2021-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/red-packet-design-2021.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-red-packet-design-2021-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-shopline-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/shopline.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-shopline-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-spring-hills-2018-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/spring-hills-2018.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-spring-hills-2018-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-tyson-accessibility-guideline-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/tyson-accessibility-guideline.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-tyson-accessibility-guideline-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-tyson-ars-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/tyson-ars.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-tyson-ars-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-tyson-design-system-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/tyson-design-system.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-tyson-design-system-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-tyson-translate-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/tyson-translate.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-tyson-translate-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-wing-pal-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/kayzhang/Documents/Personal Website/kay-space/work/wing-pal.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-wing-pal-mdx" */)
}

